import { graphql } from 'gatsby'
import React from 'react'
import ContentPage from '~/components/Content/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import useBanners from '~/hooks/components/use-banners'

const IndexPage = ({ data }) => {
  const { page } = data
  const { slug } = page

  const banner = useBanners(slug)

  return (
    <Layout banner={banner}>
      <Metadata />
      <ContentPage page={page} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    page: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`
